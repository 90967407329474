.navbar {
    background: rgba(250, 250, 252, .8);
    backdrop-filter: saturate(180%) blur(20px);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999999999;

  }
  
  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    position: relative;
  }
  
  .nav-logo {
    color: #000;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
    
    display: flex;
    align-items: center;
    justify-items: center;

  }

  .nav-logo:hover{
    color: #000;
  }

  .nav-logo .icon {
    display: flex;
    align-items: center;
    width: 40px;
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
    margin-bottom: 0;
  }
  
  .nav-links {
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
    font-size: 16px;
  }
  .nav-links:hover{
    color: #000;
    text-decoration: underline;
  }
  /* .fa-code {
    margin-left: 1rem;
  } */
  
  .nav-item {
    line-height: 40px;
    margin-right: 1rem;
  }
  
  /* .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav-item:hover:after {
    width: 100%;
    background: #000;
  } */
  
  .nav-item .active {
    color: #000;
  }
  
  .nav-icon {
    display: none;
    color: #000;
  }
  
  .nav-icon .icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
  }
  @media screen and (max-width: 1200px) and (min-width: 961px){
    .nav-links{
      padding: 0.5rem 0.5rem;
      font-size: 12px;
    }
    .nav-logo .logo-text{
      font-size: 20px;
    }
  }
  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      position: absolute;
      top: 100%;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
      height: 100vh;
      padding-left: 0;
      margin-right: 0 !important;

    }
    .nav-item{
      border-bottom: 1px solid #4f4f4f;
      margin-right: 0;

    }
  
    .nav-menu.active {
      background: #fff;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item .active {
      color: #000;
      border: none;
    }
    .nav-links {
      padding: 1.5rem;
      width: 100%;
      display: table;
    }
  
    .nav-logo .icon {
      width: 50px;
    }
  
    .nav-icon {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-100%, -50%);
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      color: #000;
    }
    .nav-logo  .logo-text{
      font-size: 16px;
    }
  }