.project-images-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    .item{
    }
    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        border: 1px solid #000;
        display: block;
        position: relative;
        max-width: 100%;
        object-fit: contain;
    }
}

.project-detail-main-wrapper{
    display: grid;
    padding: 40px 0;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 40px;
    @media(max-width: 768px){
        grid-template-columns: 1fr;

    }
}

.project-keys-wrapper{
    .project-key{
        font-style: italic;
        margin-bottom: 0;
    }
}
.project-text-wrapper{
    .project-title{
        font-size: 80px;
        @media(max-width: 768px){
            font-size: 45px;
    
        }

    }
    .project-sub-title{
        font-size: 20px;
    }

    @media(max-width: 768px){
        grid-template-columns: 1fr;

    }

    h2{
        font-weight: bold;
    }
}