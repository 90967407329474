.services-items-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 40px;
    @media(max-width: 1024px){
        grid-template-columns: 1fr 1fr;
    }
    @media(max-width: 768px){
        grid-template-columns: 1fr;

    }
    .item{
        position: relative;
        border: 1px solid #000;
        aspect-ratio: 1 / 1;
        .description-box{
            position: absolute;
            bottom: 0;
            // background: rgba($color: #000, $alpha: 0.8);
            background: linear-gradient(rgba(#000, 0), rgba(#000, 0.5),   rgba(#000, 0.9), rgba(#000, 1));
            width: 100%;
            color: #fff;
            font-weight: 400;
            padding: 40px 20px ;
            // border-top: 1px solid #ffffff7d;
            .title{
                font-size: 27px;
                text-transform: uppercase;
            }
            .type{
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 1px;
            }
        }
        &:hover{
            .description-box{
                background: rgba($color: #fff, $alpha: 0.1);
                color: #000000;

            }
            &:before{
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                position: absolute;
                content: '';
                background: linear-gradient(rgba(#000, 0.1),  rgba(#fff, 0.7), rgba(#fff, 1));
            }
           
        }
        img{
           left: 0;
           right: 0;
           top: 0;
           bottom: 0;
           aspect-ratio: 1 / 1;
           object-fit: cover;
           width: 100%;
           height: 100%;

        }
    }
}