
.nav-links{
    font-family: 'Lato';
    letter-spacing: 2px;
}
.logo-text{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 27px;
    margin-left: 20px;
    font-weight: bold;
    color: #000;


    small{
        font-size: 14px;
        letter-spacing: 1px;
  
    }
    @media(max-width: 768px){
        display: none;
    }
}

nav.navbar{
   @media(min-width: 961px){
    ul.nav-menu li:last-child{
        border-left: 1px solid #000;
    }
   }
    

}
