#intro-wrapper{
    overflow: hidden;
    position: relative;
}
.intro{
    z-index: 1;
    width: 100%;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
  .intro-right-text-column{
    border-left: 1px solid #000;
    padding-left: 40px;
    word-break: break-word;
  }
    .overlay{
        padding: 20px;
        width: 100%;
        height: 100vh;
        position: relative;
        color: #000;
        display: flex;
        align-items: center;
     
    }
    .photo{
       display: block;
       width: 200px;
       max-width: 100%;
       display: flex;
       justify-content: center;

        #myphoto{
            width: 100%;
            height: fit-content;
            background-size: cover;
            aspect-ratio: 1/1;
            border: 1px solid #000;
            background: #fff;
            border-radius: 100%;
            @media(max-width: 768px){
                margin: auto;
            }
           
        }
    
    }
    h1{
   
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .name{
        font-size: 50px;
        @media(max-width: 768px){
            font-size: 40px;
        }
        @media(max-width: 500px){
            font-size: 30px;
        }
    }
    .designation{
        color: #666666;
        font-size: 25px;
        @media(max-width: 500px){
            font-size: 16px;
        }

    }
    .p-1{
        font-size: 27px;
        color: #626262;
    }
    .p-2{
        font-size: 21px;
        color: #626262;
    }
    .social-media-icons{
        display: flex;

        a{
            text-decoration: none;
            color: #000;
            margin-right: 20px;
            i{
                color: #000;
                font-size: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
       
    }
    
}
  
.section-marquee-text{
    .text-item{
        font-size: 70px;
        padding: 0 30px;
        font-weight: 700;
        line-height: normal;
        color: #000;
        text-transform: uppercase;
        @media(max-width: 600px){
            font-size: 40px;
        }
    }
}
#tsparticles {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
 
}
.parallax-home-wrapper{
    img{
        object-fit: cover;
    }
}
.parallax-home{
    @media(max-width: 600px){
        max-height: 300px;
    }
    &::before{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        content: '';
        background: rgba($color: #000000, $alpha: 0.6);
    }
    .inner-text{
        padding: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #fff;
        line-height: normal;
        text-transform: uppercase;
        width: 100%;
        font-weight: 900;
        font-size: 70px;
        @media(max-width: 768px){
            font-size: 60px;
        }
        @media(max-width: 600px){
            font-size: 40px;
        }
        
    }
}
  
.intro-section-wrapper{
    padding: 0 !important;
    .photo{
        object-fit: contain;

    }
    .right-text-box{
        border-left: 1px solid #000;  
        padding-left: 40px;
        word-break: break-word;

        
    }
    display: flex;
    justify-content: center;
    width: fit-content;
    max-width: fit-content;
    gap: 40px;

    @media(max-width: 600px){
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        .right-text-box{
            padding-left: 0;
            border-left: 0;
        }

    }

}
.section-technical-stack{
    h2{
        font-weight: 900 !important;
    }
}